import 'regenerator-runtime/runtime'
import React from 'react';
import { render } from 'react-dom';
import { Corrector } from './Corrector'
import { VersionSelector } from './VersionSelector'
import { isMobileBrowser } from './isMobileBrowser';
import { AlertAboutCorrections } from './AlertAboutCorrections';

const isMobile = isMobileBrowser();

if (!isMobile) {
    const correctorRoot = document.createElement('div');
    document.body.appendChild(correctorRoot);

    render(<Corrector target={document.querySelector('.article')} />, correctorRoot);
    
    const footerLeft = document.createElement('div');
    const footerContainer = document.querySelector('footer .container');
    footerContainer.appendChild(footerLeft);

    render(<AlertAboutCorrections/>, footerLeft);
}

(window as any).$(() => {
    var navbar = document.querySelector<HTMLElement>('.subnav .container') as any;
    navbar.style.display = 'flex';
    navbar.style.justifyContent = 'space-between';
    navbar.style.alignItems = 'center';
    render(<VersionSelector />, navbar)
})