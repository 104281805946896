import React, { useEffect, useState } from 'react'

export const VersionSelector = () => {
    const [versions, setVersions] = useState<{
        url: string;
        name: string;
    }[]>();

    useEffect(() => {
        fetch(window.location.origin + '/versions.json')
            .then(function (response) { return response.ok ? response.json() : new Promise(() => []) })
            .then(setVersions);
    }, [])

    if (!versions)
        return null;

    const latestVersionUrl = versions?.[0].url;
    const currentVersion = versions.find((item, index) => {
        const isAbsoluteUrlPattern = /^https?:\/\//i;
        if (isAbsoluteUrlPattern.test(item.url)) {
            return location.href.startsWith(item.url) || (index == 0 && location.href.startsWith("latest"))
        } else {
            const path = location.pathname.replace(/^\//, '');
            return path.startsWith(item.url.replace(/^\//, '')) || (index == 0 && path.startsWith("latest"));
        }
    })

    return <div style={{
        display: 'flex',
        alignItems: 'baseline',
        marginLeft: 'auto'
    }}>
        <label style={{
            fontWeight: 'normal',
            paddingRight: 4
        }}>Версия: </label>
        <select
            className='form-control'
            value={currentVersion?.url || ''}
            onChange={(e) => {
                window.location.href = location.origin + '/' + (e.target.value == latestVersionUrl ? 'latest' : e.target.value) + '/' + window.location.pathname.replace(/^\/.*?\//, '')
            }}>
            {versions.map(v => <option key={v.url} value={v.url}>{v.name.replace('_', ' ')}</option>)}
        </select>
    </div>
}