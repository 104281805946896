import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications'

const Corrector: React.FC<{
    target: HTMLElement;
}> = ({ target }) => {
    const { addToast } = useToasts();

    const buttonRef = useRef<HTMLButtonElement>();

    const [popupAnchor, setPopupAnchor] = useState<{ left: number, top: number }>();

    const hidePopup = useCallback((e?) => {
        if (!e || e.target != buttonRef.current)
            setPopupAnchor(null);
    }, [])

    useEffect(() => {
        if (!target)
            return;

        const showPopup = e => {
            if(!e.altKey)
                return;
            setTimeout(() => {
                const selection = window.getSelection();
                const text = selection.toString();
                if (selection.rangeCount && !selection.isCollapsed && text.length < 100) {
                    const getRange = selection.getRangeAt(0);
                    const rect = getRange.getBoundingClientRect();
                    const left = (rect.right + rect.left) / 2;
                    const top = rect.top;
                    setPopupAnchor({ left, top })
                }
                else {
                    setPopupAnchor(null)
                }
            }, 25)
        };

        target.addEventListener('mouseup', showPopup);

        document.addEventListener('mousedown', hidePopup);

        return () => {
            target.removeEventListener('mouseup', showPopup);
            document.removeEventListener('mousedown', hidePopup);
        }
    }, [target])

    if (!popupAnchor)
        return null;

    return <div style={{
        zIndex: 1000,
        position: 'fixed',
        ...popupAnchor
    }}>
        <div
            style={{
                position: 'absolute',
                bottom: 5,
                transform: 'translateX(-50%)',
                whiteSpace: 'nowrap'
            }}>
            <button
                ref={buttonRef}
                className='btn btn-success'
                style={{
                    outline: 'none'
                }}
                onClickCapture={async e => {
                    e.stopPropagation();
                    e.preventDefault();
                    hidePopup();
                    const selection = window.getSelection();
                    const text = selection.toString();
                    const res = await fetch('/api/notifyError', {
                        method: 'POST',
                        body: JSON.stringify({fragment: text}),
                        headers: { 'Content-type': 'application/json' }
                    });
                    if (res.ok) {
                        addToast('Спасибо за помощь! Мы проверим отмеченный фрагмент текста и исправим найденные ошибки.', { appearance: 'success', autoDismiss: true });
                    }                    
                }}
            >
                Оповестить об ошибке
            </button>
        </div>
    </div>
}

const CorrectorWrapper = (props) =>
    <ToastProvider placement='bottom-right'>
        <Corrector {...props} />
    </ToastProvider>

export {
    CorrectorWrapper as Corrector
}