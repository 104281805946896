import React from 'react'
import { useMediaQuery } from './useMediaQuery';

export const AlertAboutCorrections = () => { 
    const visible = useMediaQuery('(min-width: 700px)');
    if(!visible)
        return null;

    return <span style={{
        color: 'darkcyan', 
        fontSize: '1rem'
    }}>Нашли ошибку в тексте? Выделите фрагмент текста с зажатой клавишей Alt и мы её исправим!</span>
}